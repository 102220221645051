<template>
  <v-card>
    <v-card-title class="pb-8">
      <v-icon left>mdi-office-building</v-icon>
      Estabelecimentos
    </v-card-title>
    <v-card-text>
      <div>Selecionados: {{ form.establishments.length }}</div>

      <v-simple-table ref="table" fixed-header height="500px" class="elevation-1">
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(item, index) in headers" :key="item.text + index" :colspan="item.colspan">
                {{ item.text }}
              </th>
            </tr>
            <tr v-show="loading">
              <th colspan="7" :style="{ height: '4px', padding: 0 }">
                <v-progress-linear indeterminate />
              </th>
            </tr>
          </thead>
          <tbody class="caption">
            <template v-for="(item, i) in empresas">
              <tr>
                <td width="5" class="pr-0">
                  <v-checkbox
                    :id="item.socialName.substring(0, 10) + i"
                    :value="item.id"
                    :input-value="selectedCompanies[item.id] === 'ALL' ? item.id : false"
                    :indeterminate="selectedCompanies[item.id] === 'HAS_ONE'"
                    @click="selectCompany(item)"
                  ></v-checkbox>
                </td>
                <td width="5" class="pa-0 px-1">
                  <v-btn icon small @click="expand(item)">
                    <v-icon>{{ item.exp ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </td>
                <td>
                  <div>
                    <strong>{{ item.socialName }}</strong>
                  </div>
                  <div>
                    <span>CNPJ: {{ item.details.cnpj | cnpj }}</span>
                    <span class="pl-2">CNAE: {{ item.details.cnae | cnae }}</span>
                  </div>
                </td>
                <td>{{ item.details.stateCode }}</td>
                <td>{{ item.details.city }}</td>
              </tr>
              <template v-if="item.exp && item.establishments">
                <tr v-for="(itemDetail, i) in item.establishments" :key="itemDetail.id" class="table-estabelecimento">
                  <td colspan="2" class="text-right pr-0">
                    <div class="d-flex justify-end">
                      <v-checkbox
                        :id="itemDetail.socialName.substring(0, 11) + i"
                        v-model="form.establishments"
                        :value="itemDetail.id"
                      ></v-checkbox>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>{{ itemDetail.socialName }}</strong>
                    </div>
                    <div>
                      <span>CNPJ: {{ itemDetail.details.cnpj | cnpj }}</span>
                      <span class="pl-2">CNAE: {{ itemDetail.details.cnae | cnae }}</span>
                    </div>
                  </td>
                  <td>{{ itemDetail.details.stateCode }}</td>
                  <td>{{ itemDetail.details.city }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AdminServices from '@/services/adminServices.js';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { eventBus } from '../../../../main.js';

export default {
  props: ['id'],

  data() {
    return {
      form: {
        establishments: [],
      },

      userEstablishments: [],
      page: 0,
      totalPages: 999,

      loading: false,
      loadingSave: false,
      headers: [
        { text: '', value: 'data-table-select' },
        { text: '', value: 'data-table-expand' },
        { text: 'Razão social', align: 'start', value: 'socialName' },
        { text: 'UF', align: 'start', value: 'details.stateCode', sortable: false },
        { text: 'Cidade', align: 'start', value: 'details.city', sortable: false },
      ],

      empresas: [],
    };
  },

  computed: {
    selectedCompanies() {
      const companies = {};

      const selected = cloneDeep(this.form.establishments);

      this.empresas.forEach((company) => {
        const establishments = company.establishments.map((item) => item.id);
        let oneInclued = false;
        let allInclued = true;
        if (establishments.length === 0) allInclued = false;
        establishments.forEach((id) => {
          const inclued = selected.includes(id);
          if (inclued) oneInclued = true;
          else allInclued = false;
        });

        let result = false;
        if (allInclued) result = 'ALL';
        else if (!allInclued && oneInclued) result = 'HAS_ONE';

        companies[company.id] = result;
      });

      return companies;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },
    },
  },

  created() {
    this.fetch();
    this.fetchFromUser();
    this.fetchMore = () => {
      if (this.loading) return;
      this.page = ++this.page;
      this.fetch();
    };
  },

  mounted() {
    const el = this.$refs.table.$el.children[0];
    el.onscroll = () => {
      const elInner = this.$refs.table.$el.children[0];
      const bottomOfWindow = elInner.scrollTop + elInner.clientHeight + 200 >= elInner.scrollHeight;
      if (bottomOfWindow) {
        this.fetchMore();
      }
    };
  },

  methods: {
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
    async fetchFromUser() {
      let { data } = await AdminServices.userEstablishment.get(this.id);

      let userEstablishments = data.map((item) => item.establishment);

      userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);
      this.form.establishments = userEstablishments.map((item) => item.id);
      this.userEstablishments = cloneDeep(data.map((item) => item.id));
    },

    async fetch() {
      //TODO Izidoro, analisar pageable -> CompanyController.search();
      if (this.loading || this.page + 1 > this.totalPages) return;
      this.loading = true;
      try {
        const response = await AdminServices.company.get({
          sort: 'socialName,ASC',
          page: this.page,
          size: 30,
        });
        this.totalCompanies = response.data.totalElements;
        const parsed = response.data.content.map((item) => {
          if (item.establishments) {
            item.establishments = sortBy(item.establishments, ['details.stateCode', 'details.city', 'details.cnpj']);
          }
          return item;
        });

        this.totalPages = response.data.totalPages;
        if (response.data.pageable.pageNumber > 0) {
          this.empresas = [...this.empresas, ...parsed];
        } else {
          this.empresas = parsed;
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    expand(item) {
      item.exp = !item.exp;
      this.empresas = [...this.empresas];
    },

    selectCompany(company) {
      const selected = cloneDeep(this.form.establishments);
      const establishments = company.establishments.map((item) => item.id);
      if (establishments.every((i) => selected.includes(i))) {
        establishments.forEach((item) => {
          selected.splice(selected.indexOf(item), 1);
        });
        this.form.establishments = selected;
      } else {
        this.form.establishments = uniq([...selected, ...establishments]);
      }
    },

    async save() {
      this.loadingSave = true;
      try {
        let listSavePromise = [];
        this.form.establishments.forEach((id) => {
          const user = { userId: this.id, establishment: { id } };
          listSavePromise.push(user);
        });
        await AdminServices.userEstablishment.saveAll(listSavePromise, this.id);
      } catch (e) {
        console.log(e);
      }
      this.loadingSave = false;
      this.showToast('Alterações realizadas com sucesso!', 'success', 78);
    },
  },
};
</script>

<style scoped>
.table-estabelecimento >>> .v-data-table__expanded__row {
  background: var(--v-secondary-lighten5);
}
.table-estabelecimento >>> .v-data-table__expanded__row:hover {
  background: var(--v-secondary-lighten5) !important;
}
</style>
